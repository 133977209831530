/*
 * @Author: wubo
 * @Date: 2023-08-04 11:35:38
 * @Description:
 * @LastEditTime: 2023-12-18 20:31:25
 * @LastEditors: zuoyuxing001
 */
import { lazy, useLayoutEffect } from 'react';
import { useRoutes, Navigate, useLocation } from 'react-router-dom';

import Layout from '@/layout/BasicLayout';
import ErrorPage from '@/pages/ErrorPage';
import withSuspense from '@/hoc/LazySuspense';
import HomeV2 from '@/pages/HomeV2';
import { demos } from '@/demos';
import { useDA } from '@/hooks';

const Chat = withSuspense(lazy(() => import('@/pages/Chat')));
const Terms = withSuspense(lazy(() => import('@/pages/Terms')));
const Discover = withSuspense(lazy(() => import('@/pages/Discover')));
const Releasenote = withSuspense(lazy(() => import('@/pages/Releasenote')));
const Contact = withSuspense(lazy(() => import('@/pages/Contact')));
const IconPage = withSuspense(lazy(() => import('@/pages/AllIcon')));
const WritingV2 = withSuspense(lazy(() => import('@/pages/WritingV2')));
const WritingEdit = withSuspense(lazy(() => import('@/pages/Writing/components/Edit')));
const WorkSpace = withSuspense(lazy(() => import('@/pages/WorkSpace')));
const Doc = withSuspense(lazy(() => import('@/pages/Doc')));
const PPT = withSuspense(lazy(() => import('@/pages/PPT')));
const More = withSuspense(lazy(() => import('@/pages/More')));
const Notify = withSuspense(lazy(() => import('@/pages/Notify')));

const { VITE_APP_NAME } = import.meta.env;

const conversation = window?.location?.pathname?.match(
  /^\/chats\/([a-f\d]{4}(?:[a-f\d]{4}-){4}[a-f\d]{12})$/i,
)?.[1];

const Routes = () => {
  const location = useLocation();
  const { clearPageProperty } = useDA();

  const routes = [
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          path: '/',
          element: <HomeV2 />,
        },
        {
          path: 'ai-reader',
          element: <Doc />,
        },
        {
          path: 'ai-presentation',
          element: <PPT />,
        },
        {
          path: 'more/:tab?/:category?',
          element: <More />,
        },
        {
          path: 'chat/:conversation',
          element: <Chat />,
        },
        {
          path: 'discover',
          element: <Discover />,
        },
        {
          path: 'releasenote',
          element: <Releasenote />,
        },
        // TODO: 路由重构的重定向逻辑，可以考虑广告投放无以下规则链接后下掉
        {
          path: 'chats',
          element: <Navigate to={`/${window?.location?.search}`} replace />,
        },
        {
          path: 'chats/templates',
          element: <Navigate to={`/more${window?.location?.search}`} replace />,
        },
        {
          path: 'chats/:conversation',
          element: conversation ? <Navigate to={`/chat/${conversation}`} replace /> : <ErrorPage />,
        },
        {
          path: 'ai-writing',
          element: <WritingV2 />,
        },
        {
          path: 'ai-writing/:docId',
          element: <WritingEdit />,
        },
        {
          path: 'files/:tab?/:category',
          element: <WorkSpace />,
        },
        {
          path: 'notify',
          element: <Notify />,
        },
        ...demos(),
      ].filter(Boolean),
    },
    {
      path: 'icon',
      element:
        VITE_APP_NAME === 'boe' || VITE_APP_NAME === 'development' ? <IconPage /> : <ErrorPage />,
    },
    {
      path: 'terms/:type',
      element: <Terms />,
    },
    {
      path: 'contact',
      element: <Contact />,
    },
    {
      path: '404',
      element: <ErrorPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: '*',
      element: <ErrorPage />,
      errorElement: <ErrorPage />,
    },
  ];

  useLayoutEffect(() => {
    clearPageProperty();
    // setPage(location.pathname);
  }, [location.pathname]);
  return useRoutes(routes);
};

export default Routes;
