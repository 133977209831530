import React, {
  useEffect,
  useMemo,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from 'react';

import { Space, Spin, Input, Tooltip, Form, Dropdown, MenuProps } from 'antd';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import type {
  ResponseType,
  AdvanceConfigSettings,
  AdvanceConfigSettingItem,
  CreateChannelOptions,
} from '@/type';
import { useGlobalModal } from '@/layout/BasicLayout';
import CustomIcon from '@/components/CustomIcon';
import { useUserStore, useChatStore, useEnhanceUploadStore } from '@/store';
import { getAdvanceConfigSettings } from '@/services';
import SettingSelectItem from './components/SettingSelectItem';
import './index.less';
import UserBadge from '@/layout/BasicLayout/components/UserBadge';
import { AB_EXPERIMENTS } from '@/common/config';
import { useSundry } from '@/hooks';

//PPT_GROUP: key是接收后端传参的参数名,value是forme字段的name
const PPT_GROUP: Record<string, string> = {
  pptPage: 'page',
  pptWordAmount: 'wordAmount',
  pptAudience: 'audience',
  pptReference: 'reference',
  pptModel: 'pptModel',
  pptTone: 'pptTone',
  pptForm: 'form',
  pptImage: 'image',
  enhanceRule: 'enhanceRule',
};

const GROUP_KEYS = Object.keys(PPT_GROUP);

interface PPTControllerProps {
  promptTemplateId?: number; //ppt模板ID
}

export interface PPTControllerRef {
  getValues: () => CreateChannelOptions;
  getIsLoading: () => boolean;
}

const PPTController = forwardRef<PPTControllerRef, PPTControllerProps>((props, ref) => {
  const { promptTemplateId } = props;
  const { checkPayStatus } = useGlobalModal();
  const { userInfo } = useUserStore();
  const {
    lastPPTTemplateId,
    setLastPPTTemplateId,
    pptSettings,
    setPptSettings,
    pptAudienceCustomize,
    setPptAudienceCustomize,
    pptSlidesCustomize,
    setPptSlidesCustomize,
  } = useChatStore();
  const { file } = useEnhanceUploadStore();
  const { t } = useTranslation();
  const { experiment } = useSundry();
  const memberShipFeature = experiment(AB_EXPERIMENTS.MEMBERSHIP);

  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [audienceInputValue, setAudienceInputValue] = useState('');
  const [showAudienceInputTip, setShowAudienceInputTip] = useState(false);
  const [slidesInputValue, setSlidesInputValue] = useState('');
  const [enhanceRuleKeyPath, setEnhanceRuleKeyPath] = useState<string[] | undefined>(undefined);

  const isLoadingConfig = useRef(false);

  const settingRef = useRef<HTMLDivElement>(null);
  const [fillContent, setFillContent] = useState(false);
  useEffect(() => {
    if (!isLoading && settingRef.current) {
      const fittedWidth = 5 * 140 + 8 * 4;
      if (settingRef.current.offsetWidth > fittedWidth) {
        setFillContent(true);
      }
    }
  }, [isLoading]);

  useImperativeHandle(ref, () => ({
    getValues: () => form.getFieldsValue(),
    getIsLoading: () => isLoadingConfig.current,
  }));

  const wordAmountItemWrapper = (options: AdvanceConfigSettingItem[]) => {
    const type: { [key: string]: string } = {
      noParagraph: 'noParagraph',
      brief: 'shortParagraph',
      regular: 'regularParagraph',
      detailed: 'longParagraph',
    };
    return options.map((option, index) => {
      return {
        ...option,
        name: (
          <Space size={4}>
            <CustomIcon
              className={classNames({
                'toolbar-icon': true,
                rotate: index > 0,
              })}
              type={type[option.code]}
            />
            <span>{option.name}</span>
          </Space>
        ),
      };
    });
  };

  const imageSouceItemWrapper = (options: AdvanceConfigSettingItem[]) => {
    const itemType: { [key: string]: string } = {
      aISearch: 'aiSearch',
      aIGeneration: 'aiGeneration',
    };
    return options.map((option) => {
      return {
        ...option,
        name: (
          <div className={classNames({ 'ppt-settings__pro-option': option.limitPro })}>
            <Space size={4}>
              {itemType[option.code] && (
                <CustomIcon className="toolbar-icon" type={itemType[option.code]} />
              )}
              <span className="pro-label">{option.name}</span>
            </Space>
          </div>
        ),
      };
    });
  };

  const optionWithProBadge = (option: AdvanceConfigSettingItem) => {
    return (
      <div className={classNames({ 'ppt-settings__pro-option': option.limitPro })}>
        {option.name}
        {option.limitPro &&
          (memberShipFeature ? (
            <div className="ppt-settings__pro-badge">
              <UserBadge renderType="featureHint" />
            </div>
          ) : (
            <CustomIcon className="ppt-settings__pro-badge" type="proBadge" />
          ))}
      </div>
    );
  };

  const imageSourceChangeHandler = ({ option }: { option: AdvanceConfigSettingItem }) => {
    if (option.forbidForPay) {
      checkPayStatus?.({
        source: `PPTIMAGESOURCE${option.code}`,
      });
    }
  };

  //高级选项设置付费卡点
  const pageOptionRender = (option: AdvanceConfigSettingItem) => {
    return (
      <div
        className={classNames({
          'pro-ppt-select-option': option.forbidForPay,
          'pro-ppt-select-option-member': memberShipFeature,
        })}
      >
        <span className="pro-label">{option.name}</span>
        {memberShipFeature && ['page20', 'page25'].includes(option.code) && (
          <UserBadge renderType="featureHint" />
        )}
      </div>
    );
  };

  const pageChangeHandle = ({ option }: { option: AdvanceConfigSettingItem }) => {
    if (option.forbidForPay) {
      checkPayStatus?.({
        shortMessage: t('pages.presentation.upToPages', {
          pageNum: option.name,
        }),
        source: `PPTPAGE${option?.name}`,
      });
    }
  };

  const audienceDropdownRender = (
    menu: React.ReactElement,
    inputValue: string, // 控制用户输入的参数
    setInputValue: (inputValue: string) => void, // 设置用户输入的参数
    customItemData: AdvanceConfigSettingItem[] | undefined, // 传入用户自定义的数据列表
    setCustomItemData: (itemList: AdvanceConfigSettingItem[]) => void, // 设置用户自定义的数据列表
    pptSettingsItemData: AdvanceConfigSettingItem[] | undefined, // settings 里面的数据列表，对应于 audience 或者 slides
  ) => (
    <div style={{ width: 'fix-content' }}>
      {menu}
      <div style={{ padding: '4px 0' }}>
        <Tooltip
          title={`'${inputValue}' ${t('pages.presentation.alreadyExist')}`}
          open={showAudienceInputTip}
          getPopupContainer={(triggerNode) => triggerNode.parentElement as HTMLElement}
        >
          <Input
            maxLength={20}
            value={inputValue}
            placeholder={t('pages.presentation.inputToAddANewOption')}
            onChange={(e) => {
              setInputValue(e.target.value);
              setShowAudienceInputTip(false);
            }}
            onPressEnter={(e) => {
              e.preventDefault();
              e.stopPropagation();

              const value = (e.target as HTMLInputElement).value.trim();

              if (!value) {
                return;
              }

              //最多20个
              if ((customItemData?.length ?? 0) > 20) {
                return;
              }

              //重复校验
              if (
                [...(pptSettingsItemData ?? []), ...(customItemData ?? [])].find(
                  (item: AdvanceConfigSettingItem) => item.code === value,
                )
              ) {
                setShowAudienceInputTip(true);
                return;
              }

              setCustomItemData([
                ...(customItemData ?? []),
                {
                  code: value,
                  name: value,
                  limitPro: false,
                  customTag: true,
                  default: false,
                  forbidForPay: false,
                },
              ]);

              //clear input value
              setInputValue('');

              //scroll to new option
              const timmer = setTimeout(() => {
                const menuContainer = (
                  e.target as HTMLInputElement
                )?.parentElement?.parentElement?.querySelector('.rc-virtual-list-holder');

                menuContainer?.scrollTo(
                  0,
                  menuContainer?.scrollHeight - menuContainer?.clientHeight,
                );
                window.clearTimeout(timmer);
              }, 100);
            }}
          />
        </Tooltip>
      </div>
    </div>
  );

  const pptAudienceOptions = useMemo(() => {
    return [...(pptSettings?.pptAudience ?? []), ...(pptAudienceCustomize ?? [])];
  }, [pptSettings, pptAudienceCustomize]);

  const pptSlidesOptions = useMemo(() => {
    return [...(pptSettings?.pptForm ?? []), ...(pptSlidesCustomize ?? [])];
  }, [pptSettings, pptSlidesCustomize]);

  /**
   * enhance约束条件选项
   */
  const enhanceRuleMenus = useMemo(() => {
    return (pptSettings?.enhanceRule ?? []).map((rule) => {
      const children = rule.children?.map((childRule) => ({
        ...childRule,
        key: childRule.code,
        label: childRule.name,
      }));

      return {
        ...rule,
        key: rule.code,
        label: rule.name,
        children: children ?? undefined,
      };
    });
  }, [pptSettings]);

  const defaultEnhanceRule = useMemo(() => {
    const enhanceRuleArr = pptSettings?.enhanceRule || [];
    if (!enhanceRuleArr.length) return null;
    for (let i = 0; i < enhanceRuleArr.length; i++) {
      const item = enhanceRuleArr[i];
      if (item.default) return item;
      if (!item.children?.length) continue;
      const target = item.children.find((i) => i.default);
      if (target) return target;
    }
  }, [pptSettings]);

  const fields = useMemo(() => {
    if (!pptSettings) {
      return [];
    }

    const fields = Object.entries(pptSettings as AdvanceConfigSettings).map(([key, value]) => {
      if (key !== 'enhanceRule') {
        return {
          name: PPT_GROUP[key],
          value: value?.find((item: AdvanceConfigSettingItem) => item.default)?.code,
        };
      } else {
        return {
          name: PPT_GROUP[key],
          value: defaultEnhanceRule?.code,
        };
      }
    });
    return fields;
  }, [pptSettings, form]);

  //获取ppt设置项
  useEffect(() => {
    if (isLoading) {
      return;
    }

    const fetchPPTSettings = async () => {
      setIsLoading(true);
      isLoadingConfig.current = true;
      try {
        const { code, data } = await getAdvanceConfigSettings<ResponseType<AdvanceConfigSettings>>({
          promptTemplateId,
          groupKeys: GROUP_KEYS,
        });
        if (code === 200) {
          Object.values(data as AdvanceConfigSettings).forEach((pptSetting) => {
            pptSetting?.forEach((item: AdvanceConfigSettingItem) => {
              //付费卡点：不是会员，且不是free trail用户
              item.forbidForPay = item.limitPro && !userInfo.isPro && userInfo.membershipId !== 2;
            });
          });
          setPptSettings(data);
        }
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
        isLoadingConfig.current = false;
      }
    };
    // 避免重复请求
    if (promptTemplateId && promptTemplateId !== lastPPTTemplateId) {
      fetchPPTSettings();
      setLastPPTTemplateId(promptTemplateId);
    }
  }, [promptTemplateId]);

  const enhanceRuleLabel = useMemo(() => {
    if (!enhanceRuleKeyPath) return defaultEnhanceRule?.name;
    const keyPathLength = enhanceRuleKeyPath.length;
    if (keyPathLength === 1)
      return pptSettings?.enhanceRule?.find((rule) => rule.code === enhanceRuleKeyPath[0])?.name;
    const targetRule =
      pptSettings?.enhanceRule?.find((rule) => rule.code === enhanceRuleKeyPath[1])?.children ?? [];
    return targetRule.find((rule) => rule.code === enhanceRuleKeyPath[0])?.name ?? '';
  }, [enhanceRuleKeyPath, pptSettings]);

  const renderExtraEnhanceDropDown = () => {
    return (
      <Form.Item noStyle name="enhanceRule">
        <Dropdown
          menu={{ items: enhanceRuleMenus, onClick: handleEnhanceRuleMenuClick }}
          trigger={['click']}
        >
          <div className="ppt-setting-item">
            <div className="ppt-setting-item-label">Reference as</div>
            <div className="ppt-setting-select enhance">
              <p className="ellipsis">{enhanceRuleLabel}</p>
              <CustomIcon type="arrowBottomSquare" />
            </div>
          </div>
        </Dropdown>
      </Form.Item>
    );
  };

  const handleEnhanceRuleMenuClick: MenuProps['onClick'] = (e) => {
    form.setFieldValue('enhanceRule', e.key);
    setEnhanceRuleKeyPath(e.keyPath);
  };
  return (
    <Spin className="controller-loading" spinning={isLoading} size="small">
      {pptSettings && (
        <Form layout="vertical" form={form} fields={fields}>
          <div
            className="ppt-settings"
            ref={settingRef}
            style={{
              justifyContent: fillContent ? 'space-between' : 'flex-start',
            }}
          >
            {file && pptSettings?.enhanceRule ? (
              renderExtraEnhanceDropDown()
            ) : (
              <SettingSelectItem
                label={t('pages.presentation.pages')}
                name="page"
                form={form}
                disabled={file ? true : false}
                options={pptSettings.pptPage}
                optionRender={pageOptionRender}
                changeHandle={pageChangeHandle}
              />
            )}
            <SettingSelectItem
              label={t('pages.presentation.wordAmount')}
              name="wordAmount"
              form={form}
              disabled={file ? true : false}
              //使用optionRender时select收缩状态下看不到图标，改用label
              options={wordAmountItemWrapper(pptSettings.pptWordAmount ?? [])}
            />
            <SettingSelectItem
              label={t('pages.presentation.audience')}
              name="audience"
              form={form}
              options={pptAudienceOptions}
              dropdownRender={(menu) =>
                audienceDropdownRender(
                  menu,
                  audienceInputValue,
                  setAudienceInputValue,
                  pptAudienceCustomize,
                  setPptAudienceCustomize,
                  pptSettings.pptAudience,
                )
              }
              onDropdownVisibleChange={() => {
                setAudienceInputValue('');
                setShowAudienceInputTip(false);
              }}
            />
            <SettingSelectItem
              label={t('pages.presentation.slidesForm')}
              name="form"
              form={form}
              options={pptSlidesOptions}
              dropdownRender={(menu) =>
                audienceDropdownRender(
                  menu,
                  slidesInputValue,
                  setSlidesInputValue,
                  pptSlidesCustomize,
                  setPptSlidesCustomize,
                  pptSettings.pptForm,
                )
              }
              onDropdownVisibleChange={() => {
                setSlidesInputValue('');
                setShowAudienceInputTip(false);
              }}
            />
            <SettingSelectItem
              label={t('pages.presentation.imageSource')}
              name="image"
              placement="topLeft"
              form={form}
              options={imageSouceItemWrapper(pptSettings.pptImage ?? [])}
              optionRender={optionWithProBadge}
              changeHandle={imageSourceChangeHandler}
            />
          </div>
        </Form>
      )}
    </Spin>
  );
});

export default PPTController;
