import { useCallback, useEffect, useMemo, useRef } from 'react';
import { Divider, Input, Popover } from 'antd';
import classNames from 'classnames';

// import QuoteFiles from './components/QuoteFiles';
import TemplateOverview from './components/TemplateOverview';
import FileList from './components/FileList';
import ImageList from './components/ImageList';
import SearchTemplate from './components/SearchTemplate';
import ProcessOperators from './components/ProcessOperators';
import InputSizeTip from './components/InputSizeTip';
import AddFilesPopover from './components/AddFilesPopover';
import ExceedFileModal from './components/ExceedFileModal';
import TemplatePreview from '@/components/TemplatePreview';
import ModelSelect from '@/components/ModelSelect';
import CustomIcon from '@/components/CustomIcon';
import PPTController, { PPTControllerRef } from '@/components/PPTController';
import FileTypeTip from '@/pages/Chat/components/FileTypeTip';
import ImageUpload, { ImageUploadRef } from '@/components/ImageUpload';
import SwitchRag from '@/components/SwitchRag';

import { useGASendEvent, useSundry, useDA } from '@/hooks';
import { EFileType } from '@/type';

import './index.less';

import { useTranslation } from 'react-i18next';

import { EChatType, SendControllerViewProps } from './types';
import { GptUsageItem, useCommonStore, useSendUploadStore } from '@/store';
import { UserPromptControl } from '../InputControl';
import useInputTracker from '../InputControl/hooks/useInputTracker';
import { PreviewModel } from './components/PreviewModel';
import {
  useSendController,
  useSwtichRag,
  useSelectModel,
  useUploadImage,
  useSendTemplate,
  useSendInput,
  useSendFileUpload,
} from './hooks';
import { AB_EXPERIMENTS, DOC_SEARCH_TPL_ID } from '@/common/config';
import { getChatType } from '@/common/helpers/chatHelper';
import WritingController, { WritingControllerRef } from '../WritingController';
import SendButton from './components/SendButton';
import CaptureImage from './components/CaptureImage';
import UsageTip from '../UsageTip';

const SendControllerView: React.FC<SendControllerViewProps> = (props) => {
  const {
    stopShow = false,
    questionAddons,
    onScreenshot,
    onSwitchRagChange,
    onTplPreviewClose,
    wrapClassName,
    searchSwitch,
    isBottom = false,
    channel,
    chatType,
  } = props;

  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const pptControllerRef = useRef<PPTControllerRef | null>(null);
  const suggestionContainerRef = useRef<HTMLDivElement>(null);
  const userPromptRef = useRef<{ deactive: () => void }>(null);
  const imageUploadRef = useRef<ImageUploadRef | null>(null);
  const writingControllerRef = useRef<WritingControllerRef | null>(null);

  const { sendDAEvent } = useDA();
  const { experiment } = useSundry();
  const memberShipFeature = experiment(AB_EXPERIMENTS.MEMBERSHIP);

  // 联网开关hook
  const { switchChecked, switchRagSize, canSwitchRag, handleSwitchRag } = useSwtichRag({
    channelId: props?.channelId,
    searchSwitch,
    onSwitchRagChange,
  });
  // 模版hook
  const {
    previewKey,
    showSearchTplRes,
    realTemplate,
    showTplOverview,
    showCloseDefaultPPTTpl,
    tplList,
    searchTplShow,
    isReverseTPLBox,
    canAddTpl,
    setSearchTplShow,
    setPreviewKey,
    handleTemplatePreview,
    handleSelectTpl,
    handleCloseTemplateMode,
    handleSearchTpl,
  } = useSendTemplate(
    {
      channelId: props?.channelId,
      template: props?.template,
      chatType,
      openTplPreview: props?.openTplPreview,
      onTemplateClear: props?.onTemplateClear,
    },
    { userPromptRef },
  );
  //
  const {
    showQuestionAddons,
    showPPTModelSelect = false,
    showFileTip,
    createPPTShow,
    pdfMsgId,
    handleChooseQuestion,
    handleSuggestionActive,
    handleStopGenerateThrottle,
  } = useSendController({
    channelId: props?.channelId,
    questionAddons: props?.questionAddons,
    lastMessage: props?.lastMessage,
    selectedImageProgress: props?.selectedImageProgress,
    switchChecked,
    realTemplate,
    tplList,
    onStop: props?.onStop,
    onClearSelectedImage: props?.onClearSelectedImage,
    onSuggestionActiveChange: props?.onSuggestionActiveChange,
  });
  // 模型hook
  const { currentModel, showModelSelect, modelOptions, handleOutputModelSelect } = useSelectModel({
    switchChecked,
    channelId: props?.channelId,
    chatType,
  });
  // 图片hook
  const {
    imageUrls,
    isUploadImage,
    imgProgress,
    canAddImg,
    showAddImg,
    showCaptureImg,
    canCapture,
    handleStartImageUpload,
    handleFinishImageUpload,
    handleRemoveImages,
  } = useUploadImage(
    {
      selectedImageProgress: props?.selectedImageProgress,
      selectedImage: props?.selectedImage,
      realTemplate,
      channelId: props?.channelId,
      chatType,
      canScreenShot: props?.canScreenShot,
    },
    imageUploadRef,
  );
  // 文件hook
  const {
    canAddFiles,
    addFileUploadType,
    addFilPlacement,
    maxFilesCount,
    addFilesPopoverOpen,
    setAddFilesPopoverOpen,
    handleAddFilesPopover,
  } = useSendFileUpload({
    channelId: props?.channelId,
    realTemplate: realTemplate,
    selectedImageProgress: props?.selectedImageProgress,
    selectedImage: props?.selectedImage,
    canAddImg,
  });
  // 输入hook
  const {
    value,
    inputActive,
    maximum,
    inputExceeded,
    computedPlaceholder,
    sendLoading,
    canSend,
    maxCount,
    setValue,
    setInputActive,
    setIsComposition,
    handlePressEnter,
    handleInputChange,
    handlePaste,
    handleSendThrottle,
  } = useSendInput(
    {
      placeholder: props?.placeholder,
      channelId: props?.channelId,
      chatType,
      stopShow: props?.stopShow,
      processing: props?.processing,
      selectedImageProgress: props?.selectedImageProgress,
      selectedImage: props?.selectedImage,
      realTemplate,
      canAddImg,
      switchChecked,
      canAddTpl,
      currentModel,
      onSend: props?.onSend,
      handleCloseTemplateMode,
      handleSearchTpl,
    },
    {
      pptControllerRef,
      imageUploadRef,
      writingControllerRef,
    },
  );

  const { typingState } = useInputTracker(value);

  const { isRTLDir, usageInfo } = useCommonStore();
  const { allUploadFiles } = useSendUploadStore();

  const { sendEvent } = useGASendEvent();
  const { t } = useTranslation();

  const isDocSearch = useMemo(() => {
    return channel?.promptTemplateId === DOC_SEARCH_TPL_ID;
  }, [channel?.promptTemplateId]);

  useEffect(() => {
    textareaRef.current?.focus();
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setSearchTplShow(false);
        handleInputActive(false);
      }
    };
    textareaRef.current?.focus();

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputActive = (status: boolean) => {
    setInputActive(status);
    if (status) {
      textareaRef.current?.focus();
    } else {
      textareaRef.current?.blur();
    }
  };

  const pptSettingRender = () => {
    if (!realTemplate?.id) return null;

    return (
      <div
        className={classNames('template', isReverseTPLBox && 'reverse')}
        onClick={(e) => handleClick(e)}
      >
        {showCloseDefaultPPTTpl && (
          <CustomIcon onClick={handleCloseTemplateMode} className="close" type="close" />
        )}
        {/* 模版缩略 */}
        {showTplOverview && (
          <TemplateOverview
            closeIconType={isBottom ? 'close' : 'popai-deleta'}
            template={realTemplate}
            handleCloseTemplateMode={handleCloseTemplateMode}
            handleTemplatePreview={handleTemplatePreview}
          />
        )}
        {realTemplate?.mediaType === EFileType.PPT && (
          <>
            {showTplOverview && <div className="divider" />}
            {/* ppt模版高级配置 */}
            <div className="template-config">
              <PPTController promptTemplateId={realTemplate?.id} ref={pptControllerRef} />
            </div>
          </>
        )}
      </div>
    );
  };

  const writingSettingRender = () => {
    if (chatType === EChatType.writing) {
      return (
        <div className="template" onClick={(e) => handleClick(e)}>
          <div className="template-config">
            <WritingController ref={writingControllerRef} />
          </div>
        </div>
      );
    }
    return null;
  };

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const getSuggestionContainer = useCallback(() => {
    return suggestionContainerRef.current as HTMLElement;
  }, []);

  const cls = classNames('send-controller-view', wrapClassName, 'upgrade', isBottom && 'bottom');

  return (
    <div className={cls} ref={containerRef}>
      {!!channel?.channelId && (
        <div className="send-gradient-bg">
          <div className="gradient-box" />
          <div className="pure-white" />
        </div>
      )}
      <div ref={suggestionContainerRef} className="suggestion-container"></div>
      {/* 模版预览 */}
      {previewKey && (
        <PreviewModel onClose={() => setPreviewKey('')}>
          <TemplatePreview
            isHome={true}
            previewKey={previewKey}
            template={realTemplate}
            onClosePreview={() => {
              setPreviewKey('');
              onTplPreviewClose?.();
            }}
          />
        </PreviewModel>
      )}
      {/* 搜索模版 */}
      {showSearchTplRes && (
        <SearchTemplate
          title={t('components.send.template')}
          templates={tplList}
          onSelectTpl={handleSelectTpl}
        />
      )}
      {/* 进入chat with doc 的GUI后，输入框上方的操作按钮*/}
      {showFileTip && (
        <FileTypeTip
          canCreatePPT={allUploadFiles.length === 0}
          onChooseQuestion={handleChooseQuestion}
        />
      )}
      <ProcessOperators
        stopShow={stopShow}
        onStopGenerate={handleStopGenerateThrottle}
        createPPTShow={createPPTShow}
        pdfMsgId={pdfMsgId}
      />
      <div
        className={classNames({
          'sendview-container': true,
          active: inputActive,
        })}
        onClick={() => handleInputActive(true)}
      >
        {/* 模版区域 */}
        {pptSettingRender()}
        {/* 输入框区域，包括上传的文件、输入框、输入框下方按钮、发送按钮、模型选择、语言选择等 */}
        <div className="input-container">
          {showQuestionAddons && <div className="question-addons">{questionAddons}</div>}
          <ImageList
            imageUrls={imageUrls}
            isUploadImage={isUploadImage}
            imgProgress={imgProgress}
            handleRemoveImages={handleRemoveImages}
          />
          <FileList />
          <div className="input-area">
            <Input.TextArea
              ref={textareaRef}
              className="textarea"
              variant="borderless"
              autoSize={{ maxRows: 7 }}
              placeholder={computedPlaceholder}
              value={value}
              onChange={handleInputChange}
              onPressEnter={handlePressEnter}
              onCompositionStart={() => setIsComposition(true)}
              onCompositionEnd={() => setIsComposition(false)}
              onPaste={handlePaste}
            />
            <InputSizeTip
              maximum={maximum}
              inputExceeded={inputExceeded}
              value={value}
              maxCount={maxCount}
            />
          </div>
          {writingSettingRender()}
          <div className="input-operators">
            <div className="attachment-operator">
              {!isDocSearch && (
                <>
                  <SwitchRag
                    disabled={!canSwitchRag}
                    size={switchRagSize}
                    value={switchChecked}
                    onChange={handleSwitchRag}
                  />
                  {chatType !== EChatType.writing && <Divider type="vertical" />}
                </>
              )}

              {chatType !== EChatType.writing && (
                <>
                  {!isDocSearch && chatType !== EChatType.chatWithTPL && (
                    <Popover
                      overlayClassName="addfiles-popover"
                      arrow={false}
                      trigger="click"
                      open={addFilesPopoverOpen}
                      placement={isRTLDir ? 'topRight' : 'topLeft'}
                      content={
                        <AddFilesPopover
                          currentModel={currentModel}
                          imageUploadRef={imageUploadRef}
                          isEnhancePPT={!!realTemplate?.id}
                          type={addFileUploadType}
                          canAddImg={canAddImg}
                          canAddFiles={canAddFiles}
                          onAddFilesPopoverClose={() => setAddFilesPopoverOpen(false)}
                          onEndUploadImg={handleFinishImageUpload}
                          onStartUploadImg={handleStartImageUpload}
                          channel={channel}
                        />
                      }
                      onOpenChange={handleAddFilesPopover}
                    >
                      <CustomIcon
                        className={classNames({
                          operator: true,
                          disabled: !canAddFiles && !canAddImg,
                          active: addFilesPopoverOpen && canAddFiles,
                        })}
                        type="popai-file"
                        tooltip={
                          searchTplShow ||
                          (realTemplate?.id && realTemplate?.mediaType === EFileType.PPT)
                            ? t('components.upload.addReference')
                            : t('components.upload.uploadFiles', {
                                count: Math.min(
                                  (usageInfo.docUpload as GptUsageItem)?.total || maxFilesCount,
                                  20,
                                ),
                              })
                        }
                        placement={addFilPlacement}
                        tooltipOverlayInnerStyle={{
                          width: 'max-content',
                        }}
                        autoAdjustOverflow
                        onClick={() => {
                          sendEvent('Click_AddFile');
                          sendDAEvent('UploadFiles_ButtonClick', {
                            channelid: props?.channelId || '',
                            chattype: (props?.channel && getChatType(props?.channel)) || '',
                          });
                          handleAddFilesPopover();
                          userPromptRef.current?.deactive();
                        }}
                      />
                    </Popover>
                  )}

                  {!showCaptureImg && (
                    <ImageUpload
                      className={classNames(!showAddImg && 'hideAddImg')}
                      ref={imageUploadRef}
                      disabled={!canAddImg}
                      onStartUpload={handleStartImageUpload}
                      onFinishUpload={handleFinishImageUpload}
                    >
                      <CustomIcon
                        className={classNames({
                          operator: true,
                          disabled: !canAddImg,
                        })}
                        type="popai-image"
                        tooltip={t('common.images')}
                        onClick={() => {
                          sendEvent('Click_Picture');
                          setSearchTplShow(false);
                          userPromptRef.current?.deactive();
                        }}
                      />
                    </ImageUpload>
                  )}
                  <CaptureImage
                    showCaptureImg={showCaptureImg}
                    disabled={!canCapture || isUploadImage}
                    tooltip={t('common.ask')}
                    canCapture={canCapture}
                    onScreenshot={onScreenshot}
                  />
                  <UserPromptControl
                    displayCount={3}
                    addOnTop={true}
                    onSuggestion={setValue}
                    value={value}
                    inputTypingState={typingState}
                    getSuggestionContainer={getSuggestionContainer}
                    getInputContainer={() => containerRef.current as HTMLElement}
                    ref={userPromptRef}
                    onSuggestionActiveChange={handleSuggestionActive}
                    onClick={() => {
                      setSearchTplShow(false);
                      setPreviewKey('');
                    }}
                  />
                </>
              )}
            </div>
            <div>
              {showPPTModelSelect && memberShipFeature && <UsageTip type="ppt" />}
              {showModelSelect && (
                <ModelSelect
                  usedIn="upgrade"
                  value={currentModel}
                  options={modelOptions}
                  onChange={handleOutputModelSelect}
                />
              )}
              <SendButton
                isShowUnlimitedIcon={showPPTModelSelect && memberShipFeature}
                canSend={canSend}
                sendLoading={sendLoading}
                onSend={handleSendThrottle}
              />
            </div>
          </div>
        </div>
      </div>
      <ExceedFileModal maxCount={maxFilesCount} />
    </div>
  );
};

export default SendControllerView;
