import { useState, useEffect, useCallback, useMemo, MutableRefObject } from 'react';
import UploadFile from './UploadFile';
import { useDA, useGASendEvent, useSundry } from '@/hooks';
import PDFUrlInputSimple from '@/components/PdfUrlInput/PDFUrlInputSimple';
import UploadToEnhance from '@/components/UploadToEnhance';
import { uploadFileByUrl } from '@/services/chat';
import { InputUploadFile, UPLOAD_STATUS } from '@/store/sendUpload';
import { useCommonStore, useEnhanceUploadStore, useSendUploadStore } from '@/store';
import { Channel, ResponseType, UrlUploadResult } from '@/type';
import { Timeout } from 'ahooks/lib/useRequest/src/types';
import AddFileItemUi from '@/components/AddFileItemUi';
import HoverShadow from '@/components/HoverShadow';
import { useTranslation } from 'react-i18next';
import useGdriveUpload from '@/hooks/useGdriveUpload';
import styles from './AddFilesPopover.module.less';
import AddWorkspaceFiles from './AddWorkspaceFiles';
import { AB_EXPERIMENTS } from '@/common/config';
import ImageUpload, { ImageUploadRef } from '@/components/ImageUpload';
import classNames from 'classnames';
import { getChatType } from '@/common/helpers/chatHelper';
import { ENHANCE_FILE_MAX_PAGES } from '../constants';
import { message } from 'antd';
import UserBadge from '@/layout/BasicLayout/components/UserBadge';
import { basicChatModelName } from '@/common/model';

interface AddFilesPopoverProps {
  /**是否是enhance PPT的场景 */
  isEnhancePPT?: boolean;
  /**增加文件的模式，normal或者enhance */
  type: 'normal' | 'enhance';
  imageUploadRef?: MutableRefObject<ImageUploadRef | null>;
  canAddImg?: boolean;
  canAddFiles?: boolean;
  /**增加文件popover关闭回调 */
  onAddFilesPopoverClose: () => void;
  onStartUploadImg?: () => void;
  onEndUploadImg?: () => void;
  channel?: Channel;
  currentModel: string;
}

const AddFilesPopover: React.FC<AddFilesPopoverProps> = (props) => {
  const {
    type = 'normal',
    imageUploadRef,
    canAddImg = false,
    canAddFiles = false,
    onAddFilesPopoverClose,
    channel,
    currentModel,
  } = props;
  const { experiment } = useSundry();
  const memberShipFeature = experiment(AB_EXPERIMENTS.MEMBERSHIP);
  const { sendEvent } = useGASendEvent();
  const { preparePropertyFor, sendDAEvent } = useDA();
  const {
    allUploadFiles,
    setFileStatusMap,
    setCurrentUploadFiles,
    setAllUploadFiles,
    setuploadMethod,
    uploadMethod,
  } = useSendUploadStore();
  const {
    file: fileForPPT,
    setFile,
    setUploadProgress,
    setUploadStatus,
    setFileExtraInfo,
    setFileParams,
  } = useEnhanceUploadStore();
  useCommonStore();
  const { t } = useTranslation();
  const { gDrive, handleGdrive } = useGdriveUpload({
    onAddFilesPopoverClose,
    fromSendController: true,
  });
  const gDriveToken = gDrive?.access_token;
  const [onlineFileUrl, setOnlineFileUrl] = useState('');

  const addFilesBtnCls = useMemo(
    () => classNames('addfiles-item-shadow', { disabled: !canAddFiles }),
    [canAddFiles],
  );

  const addImgBtnCls = useMemo(
    () => classNames('addfiles-item-shadow', { disabled: !canAddImg }),
    [canAddImg],
  );

  const uploadRequest = async (file: InputUploadFile) => {
    const { uid, url, uploading, md5 } = file;

    if (url && !uploading && !md5) {
      file.uploading = true;

      const MAX_PROGRESS = 99;
      const INTERVAL = 600;
      let progress = 0;
      let timmer: Timeout;

      const updateProgress = () => {
        progress += Math.floor(Math.random() * 10);

        setFileStatusMap(uid, {
          status: UPLOAD_STATUS.Uploading,
          progress: Math.min(MAX_PROGRESS, progress),
        });

        if (progress < MAX_PROGRESS) {
          timmer = setTimeout(() => {
            updateProgress();
          }, INTERVAL);
        } else {
          window.clearTimeout(timmer);
        }
      };
      const stopTimeout = () => {
        window.clearTimeout(timmer);
      };
      try {
        updateProgress();

        const fileIndex = allUploadFiles.findIndex((item) => item.uid === uid);
        const { data } = await uploadFileByUrl<ResponseType<UrlUploadResult>>({ url: file?.url });

        // 上传成功后获取到MD5添加到对应的对象中
        if (data?.md5 && fileIndex > -1) {
          const files = [...allUploadFiles];
          const newFile = {
            ...allUploadFiles?.[fileIndex],
            md5: data?.md5,
            name: data?.fileName,
          };
          files.splice(fileIndex, 1, newFile);
          setAllUploadFiles(files);

          stopTimeout();
          setFileStatusMap(uid, {
            status: UPLOAD_STATUS.Success,
            progress: 100,
          });
        }
      } catch (err: any) {
        stopTimeout();
        file.error = err?.response?.data?.message || err?.data?.message || err?.message;
        setFileStatusMap(uid, {
          status: UPLOAD_STATUS.Failed,
          progress: 100,
        });

        throw new Error('upload by url failed');
      } finally {
        file.uploading = false;
        stopTimeout();
      }
    }
  };

  const handleURLUploaded = async (e: { url: string }) => {
    onAddFilesPopoverClose?.();

    const fileInfo = {
      uid: `url-upload-${Date.now()}`,
      name: e?.url?.split('/').pop(),
      type: 'application/pdf',
      url: e?.url,
      isUrlFile: true,
      // error: '',
      upload: () => {
        uploadRequest(fileInfo);
      },
    } as InputUploadFile;
    // 不能上传同一个url的文件
    const isAlreadyUploaded = allUploadFiles.find((i) => i.url === fileInfo.url);
    if (isAlreadyUploaded) return false;
    setCurrentUploadFiles([fileInfo]);
    setAllUploadFiles([...allUploadFiles, fileInfo]);
  };

  //handleURLUploaded（） 的下一步
  useEffect(() => {
    if (!allUploadFiles?.length || uploadMethod !== 'url') return;
    const currentFile = allUploadFiles[allUploadFiles.length - 1] as InputUploadFile;
    if (currentFile.isUrlFile && !currentFile.md5 && !currentFile.uploading) {
      uploadRequest(currentFile);
    }
  }, [allUploadFiles?.length, uploadMethod]);

  const renderUploadUI = () => {
    const { isEnhancePPT = false } = props;
    if (isEnhancePPT) {
      return (
        <UploadToEnhance onStartUpload={props?.onAddFilesPopoverClose}>
          <AddFileItemUi
            iconType="uploadDocs"
            // text="Upload from computer"
            text={t('components.upload.uploadFromComputer')}
            onClick={() => {
              sendEvent('Click_UploadFromComputer');
              sendDAEvent('UploadFiles_Click', {
                upload_method: 'computer',
                channelid: channel?.channelId || '',
                chattype: (channel && getChatType(channel)) || '',
              });
            }}
          ></AddFileItemUi>
        </UploadToEnhance>
      );
    }
    return (
      <UploadFile
        currentModel={currentModel}
        type={type}
        onBeforeUpload={props?.onAddFilesPopoverClose}
      >
        <AddFileItemUi
          iconType="uploadDocs"
          text={t('components.upload.uploadFromComputer')}
          onClick={() => {
            sendEvent('Click_UploadFromComputer');
            sendDAEvent('UploadFiles_Click', {
              upload_method: 'computer',
              channelid: channel?.channelId || '',
              chattype: (channel && getChatType(channel)) || '',
            });
            preparePropertyFor(
              'UploadDocumentResult',
              'upload_method',
              'one_chat_upload_from_computer',
              false,
            );
          }}
        >
          {isShowMemberIcon && <UserBadge renderType="featureHint" />}
        </AddFileItemUi>
      </UploadFile>
    );
  };

  const handleAddFromWorkSpace = useCallback(
    (files: InputUploadFile[]) => {
      onAddFilesPopoverClose();
      if (props.isEnhancePPT) {
        const pageCount = Number(files[0].pageCount);
        if (pageCount <= ENHANCE_FILE_MAX_PAGES) {
          setFile(files[0]);
          setFileExtraInfo({
            topic: '',
            language: '',
            page_count: pageCount,
          });
          setFileParams(files[0].uid, files[0].type);
          setUploadProgress(100);
          setUploadStatus(UPLOAD_STATUS.Success);
        } else {
          message.error(t('components.EnhanceUpload.toolTipTittle'));
          return;
        }
      } else {
        setuploadMethod('files');
        setCurrentUploadFiles([...files]);
        files.forEach((file) =>
          setFileStatusMap(file.uid, {
            status: UPLOAD_STATUS.Success,
            progress: 100,
          }),
        );
        setAllUploadFiles([...allUploadFiles, ...files]);
      }
    },
    [
      allUploadFiles,
      onAddFilesPopoverClose,
      props.isEnhancePPT,
      setAllUploadFiles,
      setCurrentUploadFiles,
      setFile,
      setFileExtraInfo,
      setFileParams,
      setFileStatusMap,
      setUploadProgress,
      setUploadStatus,
      setuploadMethod,
      t,
    ],
  );

  const handleStartUploadImg = useCallback(() => {
    props?.onStartUploadImg?.();
    onAddFilesPopoverClose?.();
  }, [props, onAddFilesPopoverClose]);

  const selectedIds = useMemo(() => {
    if (props.isEnhancePPT) {
      return [fileForPPT?.uid || ''];
    } else {
      return allUploadFiles.map((file) => file.uid);
    }
  }, [allUploadFiles, fileForPPT, props.isEnhancePPT]);

  const isShowMemberIcon = useMemo(() => {
    return memberShipFeature && currentModel !== basicChatModelName;
  }, [memberShipFeature, currentModel]);

  return (
    <div className="addfiles-popover-container">
      {!props.isEnhancePPT && (
        <HoverShadow containerCls={addFilesBtnCls}>
          <AddFileItemUi
            iconType="Gdrive"
            text={
              gDriveToken
                ? t('pages.workspace.newBtn.addGdriveFile')
                : t('pages.workspace.newBtn.connectGdrive')
            }
            onClick={() => {
              handleGdrive(currentModel);
              setuploadMethod('googleDrive');
              sendDAEvent('UploadFiles_Click', {
                upload_method: 'googledrive',
                channelid: channel?.channelId || '',
                chattype: (channel && getChatType(channel)) || '',
              });
            }}
          >
            {isShowMemberIcon && <UserBadge renderType="featureHint" />}
          </AddFileItemUi>
        </HoverShadow>
      )}
      <HoverShadow containerCls={addFilesBtnCls}>{renderUploadUI()}</HoverShadow>

      <HoverShadow containerCls={addFilesBtnCls}>
        <AddWorkspaceFiles
          currentModel={currentModel}
          selectedIds={selectedIds}
          className={styles['add-workspace-files']}
          onAdd={handleAddFromWorkSpace}
          multiSelection={!props.isEnhancePPT}
          channel={channel}
        >
          {isShowMemberIcon && !props.isEnhancePPT && <UserBadge renderType="featureHint" />}
        </AddWorkspaceFiles>
      </HoverShadow>

      <HoverShadow containerCls={addImgBtnCls}>
        <ImageUpload
          ref={imageUploadRef}
          disabled={!canAddImg}
          onStartUpload={handleStartUploadImg}
          onFinishUpload={props?.onEndUploadImg}
        >
          <AddFileItemUi
            iconType="popai-image"
            text={t('components.upload.uploadImage')}
            onClick={() => {
              sendEvent('Click_Picture');
              sendDAEvent('UploadFiles_Click', {
                upload_method: 'image',
                channelid: channel?.channelId || '',
                chattype: (channel && getChatType(channel)) || '',
              });
            }}
          />
        </ImageUpload>
      </HoverShadow>
      {!props.isEnhancePPT && (
        <>
          <HoverShadow containerCls={addFilesBtnCls}>
            <PDFUrlInputSimple
              fileUrl={onlineFileUrl}
              onFileUrlChange={(value) => {
                setOnlineFileUrl(value);
                setuploadMethod('url');
              }}
              currentModel={currentModel}
              onUploadURL={handleURLUploaded}
              placement="right"
              showPlugin={false}
              channel={channel}
            >
              {isShowMemberIcon && <UserBadge renderType="featureHint" />}
            </PDFUrlInputSimple>
          </HoverShadow>
        </>
      )}
    </div>
  );
};
export default AddFilesPopover;
