import { FileExt, getFileMineType } from '@/common/helpers/fileHelper';
import AddFileItemUi from '@/components/AddFileItemUi';
import WorkspaceModal from '@/components/WorkspaceModal';
import { ListItem } from '@/pages/WorkSpace/typing';
import { useCommonStore, useSendUploadStore, useUserStore } from '@/store';
import { InputUploadFile } from '@/store/sendUpload';
import { HTMLAttributes, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDA } from '@/hooks';
import { getChatType } from '@/common/helpers/chatHelper';
import { Channel } from '@/type';
import { useGlobalModal } from '@/layout/BasicLayout';
import { isModelPro } from '@/common/config';
import { basicChatModelName } from '@/common/model';

function AddWorkspaceFiles({
  onAdd,
  className,
  multiSelection = true,
  selectedIds,
  channel,
  children,
  currentModel = basicChatModelName,
}: {
  onAdd: (files: InputUploadFile[]) => void;
  multiSelection?: boolean;
  selectedIds: string[];
  channel?: Channel;
  children?: React.ReactNode;
  currentModel?: string;
} & HTMLAttributes<HTMLDivElement>) {
  const { sendDAEvent } = useDA();
  const { checkPayStatus, checkLoginStatus } = useGlobalModal();
  const { userInfo } = useUserStore();
  const { usageInfo } = useCommonStore();
  const { allUploadFiles } = useSendUploadStore();
  const { t } = useTranslation();

  const [showWorkspaceModal, setShowWorkspaceModal] = useState(false);

  const handleCheckloginStatus = useCallback(() => {
    const res = checkLoginStatus?.({ type: 'UploadFiles_Click', model: currentModel });
    return res;
  }, [currentModel, checkLoginStatus]);

  const handleCheckPayStatus = useCallback(() => {
    // 如果是enhance，只拦截登录
    if (!multiSelection) return true;
    if (usageInfo.docUploadLimitHint) {
      checkPayStatus?.({
        shortMessage: t('common.filesPerDay', { count: 2 }),
        source: 'FREEDAYMAXFILE',
      });
      return false;
    }
    if (!userInfo.isPro && !userInfo.isTrial && isModelPro(currentModel)) {
      checkPayStatus({
        shortMessage: t('common.responseWith', { name: currentModel }),
        source: 'GPT4Turbo',
      });
      return false;
    }
    return true;
  }, [
    currentModel,
    checkPayStatus,
    multiSelection,
    t,
    usageInfo.docUploadLimitHint,
    userInfo.isPro,
    userInfo.isTrial,
  ]);

  const handleAddFromWSClick = useCallback(() => {
    if (!handleCheckloginStatus()) return;
    if (!handleCheckPayStatus()) return;
    setShowWorkspaceModal(true);
    sendDAEvent('UploadFiles_Click', {
      upload_method: 'files',
      channelid: channel?.channelId || '',
      chattype: (channel && getChatType(channel)) || '',
    });
  }, [channel, handleCheckPayStatus, handleCheckloginStatus, sendDAEvent]);

  const handleConfirm = useCallback(
    (selected: ListItem[]): void => {
      setShowWorkspaceModal(false);
      const existingIds = new Set(allUploadFiles.map((file) => file.uid));
      const newFiles = selected.reduce((ret, file) => {
        // 不要重复添加
        if (!existingIds.has(file.uniqKey)) {
          const fileInfo = {
            uid: file.uniqKey,
            md5: file.uniqKey,
            name: file.fileName,
            type: getFileMineType(
              (file.rawFileTypeEnum as unknown as FileExt) || (file.fileType as unknown as FileExt),
            ),
            pageCount: file.filePageCount,
            uploadTime: 0,
            uploadProgress: 100,
          };
          ret.push(fileInfo);
        }
        return ret;
      }, [] as typeof allUploadFiles);

      onAdd(newFiles);
    },
    [allUploadFiles, onAdd],
  );

  const handleCancel = useCallback((): void => {
    setShowWorkspaceModal(false);
  }, []);

  return (
    <>
      <AddFileItemUi
        iconType="popai-a-workspace1"
        text={t('components.upload.addFromFiles')}
        onClick={handleAddFromWSClick}
        className={className}
      >
        {children}
      </AddFileItemUi>
      {showWorkspaceModal && (
        <WorkspaceModal
          multiSelection={multiSelection}
          onCancel={handleCancel}
          onConfirm={handleConfirm}
          selected={selectedIds}
          channel={channel}
        ></WorkspaceModal>
      )}
    </>
  );
}

export default AddWorkspaceFiles;
